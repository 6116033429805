import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

// const storage = getStorage();

// Points to the root reference
// export const storageRef = ref(storage);

const firebaseConfig = {
    apiKey: "AIzaSyCICdwqlyyHsL0AS-R4Ja910AoZnVOWki4",
    authDomain: "accedeceramics-850fb.firebaseapp.com",
    projectId: "accedeceramics-850fb",
    storageBucket: "accedeceramics-850fb.appspot.com",
    messagingSenderId: "245417901130",
    appId: "1:245417901130:web:d9ff00d76c03e710133ab3",
    measurementId: "G-MZ6PF98HNL",
    databaseURL: "https://accedeceramics-850fb-default-rtdb.firebaseio.com",
  };

const app = initializeApp(firebaseConfig);
export const db = getDatabase(app);
