/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useState, useCallback } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './index.css';
import LogoImage from "./Assest/img/appLogoSmall.png";
import { Modal, Button } from 'react-bootstrap'

import { db } from "./util";
import { onValue, ref, set } from "firebase/database";
import { getStorage, ref as StoreRef, getDownloadURL, listAll } from "firebase/storage";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



function App() {
  AOS.init();

  const [fireData, setFireData] = useState(null);
  const [homeBackground, setHomeBackground] = useState('');
  const [aboutImage, setAboutImage] = useState('');
  const [productImage, setProductImage] = useState([])
  const storage = getStorage();
  const [inputs, setInputs] = useState({});
  const [isShow, invokeModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});

  const initModal = () => {
    return invokeModal(!isShow)
  }

  const notify = (msg) => toast(`${msg}`);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({ ...values, [name]: value }))
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    const query = ref(db, "/accedeCeramic/message");
    let data = [];
    onValue(query, (snapshot) => {
      data = snapshot.val() || [];
      data = [...data, inputs];
    });
    set(query, data).then((val) => notify("Message sent sucessfully!"));
    setInputs({});
  }

  const onButtonClick = () => {
    getDownloadURL(StoreRef(storage, `${fireData?.catalogue?.pdf}`))
      .then((url) => {
        // This can be downloaded directly:
        let alink = document.createElement('a');
        alink.href = url;
        alink.target = '_blank';
        alink.download = 'SamplePDF.pdf';
        alink.click();
      })
      .catch((error) => {
        console.log('error', error);
        // Handle any errors
      });


    // using Java Script method to get PDF file

  }

  const loadDataOnlyOnce = useCallback(() => {
    if (fireData === null) {
      const query = ref(db, "/accedeCeramic");
      setFireData(null);
      setHomeBackground("");
      setAboutImage("");
      setProductImage([]);

      onValue(query, (snapshot) => {
        const data = snapshot.val();
        setFireData(data);

        listAll(StoreRef(storage, "home")).then((res) => {
          let promises = res.items.map((itemRef, i) => {
            return getDownloadURL(itemRef).then((url) => {
              return url;
            }).catch(function (error) {
              // Handle any errors
            });
          })
          Promise.all(promises).then(url => setHomeBackground(url))
        }).catch((error) => {
          console.log('home background ==>', error);
        });


        listAll(StoreRef(storage, "about")).then((res) => {
          let promises = res.items.map((itemRef, i) => {
            return getDownloadURL(itemRef).then((url) => {
              return url;
            }).catch(function (error) {
              // Handle any errors
            });
          })
          Promise.all(promises).then(url => setAboutImage(url))
        }).catch((error) => {
          console.log('about background ==>', error);
        });

        listAll(StoreRef(storage, "product")).then((res) => {
          let promises = res.items.map((itemRef, i) => {
            return getDownloadURL(itemRef).then((url) => {
              return url;
            }).catch(function (error) {
              // Handle any errors
            });
          })
          Promise.all(promises).then(url => setProductImage(url))
        }).catch((error) => {
          console.log('rrrr', error);
          // Uh-oh, an error occurred!
        });
      });

    }
  }, [fireData]);

  useEffect(() => {
    loadDataOnlyOnce();
  }, [loadDataOnlyOnce]);

  return (
    fireData ?
      <div className="App">
        <ToastContainer />

        <header id="header" className="fixed-top header-scrolled">
          <div className="container d-flex align-items-center justify-content-lg-between">
            <a href="index.html" className="logo me-auto me-lg-0">
              <img src={LogoImage} alt="" className="img-fluid" height={34} width={100} />
            </a>
            <nav id="navbar" className="navbar order-last order-lg-0">
              <ul>
                <li><a className="nav-link scrollto active" href="#hero">Home</a></li>
                <li><a className="nav-link scrollto" href="#about">About</a></li>
                <li><a className="nav-link scrollto " href="#portfolio">Product</a></li>
                <li><a className="nav-link scrollto" href="#contact">Contact</a></li>
              </ul>
              <i className="bi bi-list mobile-nav-toggle"></i>
            </nav>

            <button onClick={() => onButtonClick()} className="get-started-btn scrollto">Catalogue</button>

          </div>
        </header>

        <div id="hero" className="d-flex align-items-center justify-content-center " style={{ backgroundImage: `url(${homeBackground})` }} >
          <div className="container" data-aos="fade-up">
            <div className="row justify-content-center" data-aos="fade-up" data-aos-delay="550">
              <div className="col-xl-8 col-lg-8">
                <h1>{fireData?.home?.title || ''}</h1>
                <h2>{fireData?.home?.subTitle || ''}</h2>
              </div>
            </div>
          </div>
        </div>
        <main id="main">
          {/* About Section section */}
          <section id="about" className="about">
            <div className="container" data-aos="fade-up">
              <div className="section-title">
                <h2>About</h2>
                <p>About Us</p>
              </div>
              <div className="row">
                <div className="col-lg-6 order-1 order-lg-2" data-aos="fade-left" data-aos-delay="100">
                  <img src={aboutImage} className="img-fluid" alt="" />
                </div>
                <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content" data-aos="fade-right" data-aos-delay="100">
                  <h3>{fireData?.about?.title || ''}</h3>
                  <pre className="fontclass about-description">
                    {String.raw`${fireData?.about?.description}` || ''}
                  </pre>
                </div>
              </div>

            </div>
          </section>

          {/* products section */}

          <section id="portfolio" className="portfolio">
            <div className="container" data-aos="fade-up">

              <div className="section-title">
                <h2>Portfolio</h2>
                <p>Check our Portfolio</p>
              </div>

              <div className="row portfolio-container" data-aos="fade-up" data-aos-delay="200">

                {
                  fireData?.product?.data?.map((value, index) => (
                    <div key={index.toString()} className="col-lg-4 col-md-6 portfolio-item filter-app">
                      <div className="portfolio-wrap">
                        <img src={productImage[index] || ''} className="img-fluid" alt="" />
                        <div className="portfolio-info">
                          <h4>{value.title}</h4>
                          <div className="portfolio-links">
                            <button onClick={() => { setSelectedProduct({ data: value, proImage: productImage[index] }); initModal(); }} className="get-started-btn scrollto">
                              Detail
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
          </section>

          <Modal show={isShow} size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton onClick={initModal}>
              <Modal.Title>{selectedProduct?.data?.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <img src={selectedProduct?.proImage || ''} className="img-fluid" alt="" />
              <p>
                {selectedProduct?.data?.description}
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={initModal}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          <section id="contact" className="contact">
            <div className="container" data-aos="fade-up">

              <div className="section-title">
                <h2>Contact</h2>
                <p>Contact Us</p>
              </div>

              {/* <div>
              <iframe style="border:0; width: 100%; height: 270px;" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12097.433213460943!2d-74.0062269!3d40.7101282!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb89d1fe6bc499443!2sDowntown+Conference+Center!5e0!3m2!1smk!2sbg!4v1539943755621" frameBorder="0" allowFullScreen></iframe>
            </div> */}

              <div className="row mt-5">

                <div className="col-lg-4">
                  <div className="info">
                    <div className="address">
                      <i className="bi bi-geo-alt">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt" viewBox="0 0 16 16">
                          <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                          <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                        </svg>
                      </i>
                      <h4>Corporate Office:</h4>
                      <p>{fireData?.location?.address || ''}</p>
                    </div>


                    <div className="email">
                      <i className="bi bi-geo-alt">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt" viewBox="0 0 16 16">
                          <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                          <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                        </svg>
                      </i>
                      <h4>Factory Address:</h4>
                      <p>{fireData?.location?.FactoryAddress || ''}</p>
                    </div>


                    <div className="email">
                      <i className="bi bi-envelope">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
                          <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
                        </svg>
                      </i>
                      <h4>Email:</h4>
                      <p>{fireData?.location?.email || ''}</p>
                    </div>

                    <div className="phone">
                      <i className="bi bi-phone">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-phone" viewBox="0 0 16 16">
                          <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z" />
                          <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                        </svg>
                      </i>
                      <h4>Call:</h4>
                      <p>{fireData?.location?.mobileNo || ''}</p>
                    </div>
                  </div>

                  {/* <div className=''>
                    <a href="https://wa.me/9974775299" class="whatsapp_float"target="_blank"rel="noopener noreferrer" >
                      <div className='d-flex align-items-md-center'>
                        <div className='mx-2'>
                        <i class="fa fa-whatsapp whatsapp-icon "></i>
                        </div>
                        <div>

                         How can I help you?</div>
                        </div>
                      </a> 
                  </div> */}
                  <div>

                    <a
                      href="https://wa.me/+919974775299"
                      class="whatsapp_float"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i class="fa fa-whatsapp whatsapp-icon"></i>
                    </a>
                  </div>

                </div>

                <div className="col-lg-8 mt-5 mt-lg-0">

                  <form onSubmit={handleSubmit} className="php-email-form">
                    <div className="row">
                      <div className="col-md-6 form-group">
                        <input
                          type="text" name="name" className="form-control"
                          id="name" placeholder="Your Name" required
                          value={inputs.name || ""}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-md-6 form-group mt-3 mt-md-0">
                        <input type="email" className="form-control" name="email" id="email"
                          placeholder="Your Email" required
                          value={inputs.email || ""}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="form-group mt-3">
                      <input type="text" className="form-control" name="subject" id="subject"
                        placeholder="Subject" required
                        value={inputs.subject || ""}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group mt-3">
                      <textarea className="form-control" name="message" rows="5"
                        placeholder="Message" required
                        value={inputs.message || ""}
                        onChange={handleChange}
                      ></textarea>
                    </div>
                    <div className="my-3">
                      <div className="loading">Loading</div>
                      <div className="error-message"></div>
                      <div className="sent-message">Your message has been sent. Thank you!</div>
                    </div>
                    <div className="text-center"><button type='submit'>Send Message</button></div>
                  </form>

                </div>

              </div>

            </div>
          </section>
          <footer id="footer">
            <div className="container d-flex flex-row justify-content-between align-items-end">
              <div className="copyright">
                &copy; Copyright <strong><span>Accede Ceramic</span></strong>. All Rights Reserved
              </div>
              <div className='d-flex flex-row '>
                {
                  fireData?.social?.facebook && fireData?.social?.facebook !== '' && <a href={fireData?.social?.facebook} target="_blank" className='ml-10'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-facebook" viewBox="0 0 16 16">
                      <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                    </svg>
                  </a>
                }
                {
                  fireData?.social?.instagram && fireData?.social?.instagram !== '' && <a href={fireData?.social?.instagram} target="_blank" className='ml-10'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-instagram" viewBox="0 0 16 16">
                      <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />
                    </svg>
                  </a>
                }
                {
                  fireData?.social?.twitter && fireData?.social?.twitter !== '' && <a href={fireData?.social?.twitter} target="_blank" className='ml-10'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-twitter" viewBox="0 0 16 16">
                      <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
                    </svg>
                  </a>
                }
                {
                  fireData?.social?.linkedIn && fireData?.social?.linkedIn !== '' && <a href={fireData?.social?.linkedIn} target="_blank" className='ml-10'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-linkedin" viewBox="0 0 16 16">
                      <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
                    </svg>
                  </a>
                }
                {
                  fireData?.social?.youtube && fireData?.social?.youtube !== '' && <a href={fireData?.social?.youtube} target="_blank" className='ml-10'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-youtube" viewBox="0 0 16 16">
                      <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z" />
                    </svg>
                  </a>
                }
              </div>
            </div>
          </footer>
        </main>
      </div>
      : <></>
  );
}

export default App;
